import React from 'react';
import { FaTools, FaPhoneAlt, FaMapMarkerAlt, FaRegBuilding } from 'react-icons/fa';

const UnderConstruction = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8">
        <div className="flex items-center mb-4">
          <FaTools className="text-4xl text-yellow-500 mr-3" />
          <h1 className="text-3xl font-bold text-gray-800">Página em Construção</h1>
        </div>
        <p className="text-gray-600 mb-6">Estamos trabalhando para trazer algo incrível para você!</p>
        <div className="text-left mb-4">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Informações de Contato</h2>
          
          <div className="flex items-center text-gray-700 mt-2">
            <FaRegBuilding className="mr-2" />
            <p>ORBI PRODUTOS ODONTOLOGICOS</p>
          </div>
          <div className="flex items-center text-gray-700 mt-2">
            <FaMapMarkerAlt className="mr-2" />
            <p><strong>Endereço:</strong> ROD ANTONIO HEIL, 6250, KM 06 PARTE A30 GALPAO01 MODULOS 1 E 2, ITAIPAVA, ITAJAI/SC</p>
          </div>
          <div className="flex items-center text-gray-700 mt-2">
            <FaPhoneAlt className="mr-2" />
            <p><strong>Telefone:</strong> +55 47 93618-0395</p>
          </div>
        </div>
        <p className="text-sm text-gray-500">Obrigado por sua paciência. Voltaremos em breve!</p>
      </div>
    </div>
  );
};

export default UnderConstruction;
